@import "assets/styles/mixins";

.AppHeader {
  &Logo {
    margin-right: 16px;

    @include respond-to("medium") {
      display: none !important;
    }
  }

  &Profile {
    flex-grow: 0;
    display: flex;
    align-items: center;
    border-radius: 24px;
    padding: 8px;
    border: 1px solid rgba(white, 0.3);
  }

  &Nav {
    height: 100%;
    margin-right: 16px;
    min-height: 60px;
    display: flex;
    align-items: center;
    color: var(--white-color);
    border-bottom: 2px solid transparent;
  }

  &NavActive {
    border-bottom: 2px solid var(--white-color);
  }
}
