@import "assets/styles/variables";
@import "assets/styles/terminal";
@import "assets/styles/mixins";

html div {
  box-sizing: border-box;
}

html,
body,
#root,
.app {
  height: 100%;
}

.app {
  position: relative;
}

#root {
  overflow-x: hidden;
}

@font-face {
  font-family: Inter;
  font-display: swap;
  src: url("./assets/fonts/Inter-Regular.woff") format("woff");
}

body {
  margin: 0;
  background-color: var(--default-bg-color);
}

a {
  color: inherit;
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.invertLogo {
  .logoText {
    fill: white;
  }

  .logoBg {
    fill: white;
  }

  .logoTextBg {
    fill: var(--primary-color);
  }
}

.toast {
  font-size: 14px;
  word-break: break-word;
}

.toast-lg {
  font-size: 14px;
  min-width: 600px;
  left: -200px;
  word-break: break-word;

  @include respond-to("small") {
    min-width: 100%;
    left: auto;
  }
}
