.NotFound {
  padding: 32px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  &Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 380px;
    height: 300px;
    color: var(--white-color);
    border-radius: 16px;
    background-color: var(--primary-color);
  }
}
