.button {
  color: var(--white-color);
  background-color: var(--accent-color);
  opacity: 1;
  font-size: 14px;
  box-shadow: none;
  font-style: normal;
  font-family: var(--primary-font);
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 20px;
  border-color: var(--alto);
  border-radius: 36px;
  border-style: none;
  text-transform: none;
  height: 56px;
  padding: 0 55px;

  svg {
    margin-right: 12px;
  }

  &:hover {
    box-shadow: 0 0 4px 4px rgba(19, 74, 198, 0.2);
    transition: box-shadow 0.4s ease-in-out;
    cursor: pointer;
  }
}
