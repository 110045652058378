.BackButton {
  display: flex;
  align-items: center;

  &Control {
    margin-right: 16px;
  }

  &Text {
    word-break: break-word;
  }
}
