@import "assets/styles/mixins";

$bg-color: rgba(225, 225, 225, 0.3);

.Terminal {
  height: 100%;
  overflow: hidden;

  &HeaderContent {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &Body {
    height: 100%;
    padding: 0 64px;
    background: var(--black-color);
  }

  &Logo {
    pointer-events: none;
  }

  &Title {
    padding: 0 12px;
    margin-left: 8px !important;
    border-left: 1px solid $bg-color;
    border-right: 1px solid $bg-color;
  }

  &Buttons {
    flex-shrink: 0;

    @include respond-to("medium") {
      margin-top: 8px;
    }
  }

  &InputWrapper {
    display: flex;
    height: 120px;
    padding: 0 64px;
    background: var(--black-color);
    align-items: center;
    justify-content: center;
  }

  &Form {
    background: #3e3e3e;
    border-radius: 4px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 8px 0 24px;
  }

  &InputButton {
    flex-shrink: 0;
  }

  &Input {
    color: var(--white-color) !important;
    width: 100%;
    margin-right: 16px;
  }
}
