.InfoItemTitle {
  display: flex;
  align-items: center;

  &Text {
    font-size: 17px !important;
    opacity: 0.7;
    word-break: break-word;
  }

  &Icon {
    display: flex;
    margin-left: 8px;
    color: var(--primary-color);
  }
}
