@import "assets/styles/mixins";

.HotspotOverview {
  display: flex;
  justify-content: space-between;

  @include respond-to("medium") {
    flex-direction: column;
  }

  &Info {
    margin-right: 24px;
    margin-bottom: 24px;
  }

  &Info,
  &Actions {
    width: 100%;
    max-width: 440px;
  }

  &Item {
    margin-bottom: 16px;
  }

  &Link {
    max-width: 300px;
    justify-content: space-between;
  }

  &Button {
    height: 60px;
  }

  &Field {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    @include respond-to("medium") {
      margin-top: 16px;
    }
  }
}
