:root {
  --primary-font: "Inter", sans-serif;
  --primary-color: #5d16a6;
  --white-color: #fff;
  --black-color: #000;
  --accent-color: #134ac6;
  --text-color: #202020;
  --secondary-text-color: rgba(93, 98, 102, 0.6);
  --default-bg-color: #f5f7f9;
  --alto: #dedede;
  --toastify-color-error: #d92f3c;
}
