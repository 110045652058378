@import "assets/styles/mixins";

$width: 380px;
$breakpoints-width: 300px;

.Login {
  display: flex;
  height: 100%;

  @include respond-to("medium") {
    height: auto;
    flex-direction: column-reverse;
  }

  &Intro {
    padding: 60px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--primary-color);
  }

  &IntroContent {
    max-width: $width;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to("medium") {
      width: $breakpoints-width;
    }
  }

  &IntroBg {
    position: relative;
    margin-bottom: 36px;
  }

  &IntroIcon {
    position: absolute;
    top: -9%;
    right: -34%;
  }

  &Content {
    width: 45%;
    padding: 60px;
    display: flex;
    justify-content: center;

    @include respond-to("medium") {
      width: 100%;
      padding: 60px 20px;
      min-height: 700px;
    }
  }

  &ContentInner {
    max-width: $width;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond-to("medium") {
      width: $breakpoints-width;
    }
  }

  &ContentButton {
    margin: 42px 0 28px;
  }

  &ContentButtonWrapper {
    display: flex;
    flex-direction: column;
  }

  &ContentLink {
    color: var(--accent-color);
    opacity: 1;
    transition: all 0.2s;
    font-family: var(--primary-font);
    margin-left: 5px;

    &:hover {
      opacity: 0.7;
    }
  }

  &ContentCopyright {
    max-width: $width;
    width: 100%;
    font-size: 12px !important;
    color: var(--secondary-text-color);
  }
}
